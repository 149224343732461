import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { List, ListItem, Notification } from '@constellation/core';
import { Clock, Info } from '@constellation/core/icons';
import { v4 as uuid } from 'uuid';

import { NotificationDiv } from './SummaryPage.styled';
import {
  PAYMENT_OUTCOME,
  STATUS,
  disclaimerHelperNotes,
} from '../../constants/pageConstants';
import { PROPS } from '../../constants/propConstants';
import { usePaymentStatusContext } from '../../hooks/requestedPaymentStatus/request-payment-status-context-hooks';
import paymentStatusIdentifier from '../../utils/payments.util';

type DisclaimerDetails = {
  heading?: string;
  icon: ReactElement;
  disclaimerMessage?: string;
  sentiment: any;
};

function DisclaimerComponent(): ReactElement {
  const {
    PaymentStatusResponse: { paymentStatus },
  } = usePaymentStatusContext();
  const [disclaimerDetails, setDisclaimerDetails] =
    useState<DisclaimerDetails>();
  const status = paymentStatusIdentifier(paymentStatus);

  const renderDisclaimer = useCallback(async () => {
    switch (status) {
      case STATUS.Success:
        setDisclaimerDetails({
          icon: <Clock />,
          disclaimerMessage: PAYMENT_OUTCOME.disclaimerMessageSuccess,
          sentiment: PROPS.sentimentInfo,
        });
        break;
      case STATUS.Referred:
        setDisclaimerDetails({
          heading: PAYMENT_OUTCOME.disclaimerHeading,
          icon: <Info color="warning" data-testid="referred" />,
          sentiment: PROPS.sentimentWarning,
        });
        break;
      case STATUS.Failed:
        setDisclaimerDetails({
          heading: PAYMENT_OUTCOME.disclaimerHeading,
          icon: <Info color="warning" data-testid="failed" />,
          sentiment: PROPS.sentimentWarning,
        });
        break;
      default:
        break;
    }
  }, [setDisclaimerDetails, status]);

  useEffect(() => {
    renderDisclaimer();
  }, [renderDisclaimer]);

  return (
    <NotificationDiv data-testid="Notification">
      {disclaimerDetails && (
        <Notification
          heading={disclaimerDetails.heading}
          icon={disclaimerDetails.icon}
          headingLevel={4}
          sentiment={disclaimerDetails.sentiment}
        >
          {disclaimerHelperNotes
            .filter((list) => list.paymentStatus === status)
            .map((_item) => {
              const { bulletPoints, subHeading, oneLiner, id } = _item;
              return (
                <div key={id} tabIndex={0}>
                  {oneLiner}
                  {subHeading}
                  <List marginBottom="03" marginTop="04">
                    {bulletPoints?.map((_listItem, index) => (
                      <ListItem
                        key={uuid()}
                        weight={
                          status === STATUS.Referred && index < 1
                            ? 'bold'
                            : 'normal'
                        }
                      >
                        {_listItem}
                      </ListItem>
                    ))}
                  </List>
                </div>
              );
            })}
        </Notification>
      )}
    </NotificationDiv>
  );
}

export default DisclaimerComponent;
