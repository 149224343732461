import React from 'react';

import { IPropContextProviderComposerProps } from '../interfaces/ContextProviderComposerProps';

const ContextProviderComposer: React.FC<IPropContextProviderComposerProps> = ({
  contextProviders,
  children,
}) =>
  contextProviders.reduceRight(
    (accumulatedChildren, CurrentProvider) =>
      React.cloneElement(CurrentProvider, {}, accumulatedChildren),
    children,
  );

export default ContextProviderComposer;
