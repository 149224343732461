import React from 'react';

import { OverlayContainer, SpinnerComponent } from './Overlay.styled';
import './loader.css';

export default function Loader() {
  return (
    <OverlayContainer opacity={0.2} data-testid="loader">
      <SpinnerComponent title="Fetching details" className="spinner" />
    </OverlayContainer>
  );
}
