import React, { ReactElement, useEffect } from 'react';

import { Hr, Text } from '@constellation/core';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';

import DisclaimerComponent from './DisclaimerComponent';
import JourneyHeader from './JourneyHeader';
import PaymentDetailsComponent from './PaymentDetailsComponent';
import PaymentStatusPrompt from './PaymentStatusPrompt';
import StatusLogo from './StatusLogo';
import SummaryDetailsTitle from './SummaryDetailsTitle';
import { Subsummary, Section, GridContainer } from './SummaryPage.styled';
import { FIELD_NAMES, STATUS } from '../../constants/pageConstants';
import { usePaymentStatusContext } from '../../hooks/requestedPaymentStatus/request-payment-status-context-hooks';
import { setAnalyticsPageEvent } from '../../utils/analytics-utilities';
import Loader from '../loader/Loader';

function SummaryPage(): ReactElement {
  const params = new URLSearchParams(window.location.search);
  const paymentId = params.get(FIELD_NAMES.paymentIdKeyName);
  const location = useLocation();
  const navigate = useNavigate();
  const containsError = location?.search?.includes('error');
  const { PaymentStatusResponse, requestPaymentStatusApi } =
    usePaymentStatusContext();

  useEffect(() => {
    requestPaymentStatusApi({ paymentId });
  }, [requestPaymentStatusApi, paymentId]);

  useEffect(() => {
    if (containsError) {
      navigate('/error');
    }
    if (
      PaymentStatusResponse &&
      PaymentStatusResponse.paymentStatus === STATUS.Paid
    ) {
      setAnalyticsPageEvent(
        // tagging
        '2',
        'Payment sent',
        ['Service Action Complete', 'Success', 'Fulfilled'],
        'Payments',
        '[TBC]',
      );
    } else if (
      PaymentStatusResponse &&
      PaymentStatusResponse.paymentStatus === STATUS.Failed
    ) {
      setAnalyticsPageEvent(
        // tagging
        '3',
        'Payment not made',
        ['Declined', '[Insert Message]', ''],
        'Payments',
        '[TBC]',
      );
    }
  }, [containsError, navigate, PaymentStatusResponse]);

  return (
    <Section>
      {PaymentStatusResponse?.paymentStatus ? (
        <GridContainer>
          <JourneyHeader data-testid="journey-header" />
          <Subsummary data-testid="subsummary">
            <StatusLogo data-testid="logo" />
            {PaymentStatusResponse.paymentStatus !== STATUS.Paid && (
              <PaymentStatusPrompt data-testid="prompt" />
            )}
            <SummaryDetailsTitle />
            <Hr
              marginTop="none"
              marginBottom="none"
              data-testid="hr"
              tabIndex={-1}
              aria-hidden="true"
            />
            <PaymentDetailsComponent data-testid="details" />
          </Subsummary>
          <DisclaimerComponent data-testid="notification" />
          <Text size="s2" weight="normal" tabIndex={0}>
            {FIELD_NAMES.closeMessage}
          </Text>
        </GridContainer>
      ) : (
        <Loader />
      )}
    </Section>
  );
}

export default SummaryPage;
