export default {
  header: {
    container: 'header-container',
    docsLink: 'header-docsLink',
    feedbackLink: 'header-feedbackLink',
    supportLink: 'header-supportLink',
  },
  content: {
    container: 'content-container',
  },
  mainPage: {
    sdkHero: 'MainPage-sdk-hero',
    constellationHero: 'MainPage-constellation-hero',
    descriptionContainer: 'MainPage-description',
    descriptionTitle: 'MainPage-descriptionTitle',
    descriptionText: 'MainPage-descriptionText',
  },
  aboutUsPage: {
    mainCard: 'AboutUsPage-card',
    title: 'AboutUsPage-title',
    content: 'AboutUsPage-content',
    confluenceLink: 'footer-confluenceLink',
    ourMissionHeading: 'ourMission-heading',
    supportHeading: 'support-heading',
  },
  notFoundPage: {
    mainCard: 'NotFoundPage-card',
    title: 'NotFoundPage-title',
    subTitle: 'NotFoundPage-sub-title',
    content: 'NotFoundPage-content',
    homeLink: 'NotFoundPage-link',
  },
  footer: {
    container: 'footer-container',
    documentationLink: 'footer-documentationLink',
    confluenceLink: 'footer-confluenceLink',
  },
};
