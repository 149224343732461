/* eslint-disable import/prefer-default-export */
import { Dialog } from '@constellation/core';
import styled from 'styled-components';

export const StyleDialog = styled(Dialog)`
  @media only screen and (max-width: 541px) {
    &
      .kDiune.kDiune.kDiune.csl-dialog--no-title:not(
        .csl-dialog--no-close-button
      ) {
      margin: 0 0 0.75rem 0;
      border-radius: 16px 16px 0 0;
    }
    &
      .dpXyYz.dpXyYz.dpXyYz.csl-dialog--no-title:not(
        .csl-dialog--no-close-button
      ) {
      margin: 0 0 0.75rem 0;
      border-radius: 16px 16px 0 0;
    }
    display: flex;
    justify-content: center;
    align-items: flex-end !important;
  }
`;
