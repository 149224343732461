import React from 'react';

import { Link } from '@constellation/core';
import { NavLink as RoutingNavLink } from '@interstellar/react-app-routing';

import { StyledLinkProps } from './StyledNavLink.types';

export function StyledNavLink({
  children,
  ...rest
}: StyledLinkProps): React.JSX.Element {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Link as={RoutingNavLink} {...rest}>
      {children}
    </Link>
  );
}
