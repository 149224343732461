/* eslint-disable import/prefer-default-export */
import { Spinner } from '@constellation/core';
import styled from 'styled-components';

interface OverlayProps {
  opacity?: number;
}
export const OverlayContainer = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${(props) => props.opacity});
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinnerComponent = styled(Spinner)``;
