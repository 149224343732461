import { BRAND } from '../constants/brandConstants';

const getBrand = () => {
  const brand = (window as any).appConfig.BRAND_NAME;
  return brand || '';
};

const xlbgbrand = getBrand();
export default xlbgbrand;

export const getBrandFooter = () => {
  const brand = getBrand();
  switch (brand) {
    case BRAND.LLOYDS:
      return BRAND.LLOYDS_ADDRESS;
    case BRAND.BOS:
      return BRAND.BOS_ADDRESS;
    case BRAND.HALIFAX:
      return BRAND.HALIFAX_ADDRESS;
    default:
      return BRAND.LLOYDS_ADDRESS;
  }
};

export const getBrandTermsAndConditionsLink = () => {
  const brand = getBrand();
  switch (brand) {
    case BRAND.LLOYDS:
      return BRAND.LLOYDS_TERMS_AND_CONDITONS_LINK;
    case BRAND.BOS:
      return BRAND.BOS_TERMS_AND_CONDITONS_LINK;
    case BRAND.HALIFAX:
      return BRAND.HALIFAX_TERMS_AND_CONDITONS_LINK;
    default:
      return '#';
  }
};

export const getBrandDataPrivacyPolicyLink = () => {
  const brand = getBrand();
  switch (brand) {
    case BRAND.LLOYDS:
      return BRAND.LLOYDS_DATA_PRIVACY_POLICY;
    case BRAND.BOS:
      return BRAND.BOS_DATA_PRIVACY_POLICY;
    case BRAND.HALIFAX:
      return BRAND.HALIFAX_DATA_PRIVACY_POLICY;
    default:
      return '#';
  }
};

export const getAriaLabel = () => {
  const brand = getBrand();
  switch (brand) {
    case 'lloyds':
      return BRAND.LLOYDS_ARIA_LABEL;
    case 'bos':
      return BRAND.BOS_ARIA_LABEL;
    case 'halifax':
      return BRAND.HALIFAX_ARIA_LABEL;
    default:
      return '';
  }
};
