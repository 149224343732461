import React, { ReactElement } from 'react';

import { HeadingComponent, JourneyHeaderComponent } from './SummaryPage.styled';
import { FIELD_NAMES, STATUS } from '../../constants/pageConstants';
import { usePaymentStatusContext } from '../../hooks/requestedPaymentStatus/request-payment-status-context-hooks';
import paymentStatusIdentifier from '../../utils/payments.util';

function JourneyHeader(): ReactElement {
  const {
    PaymentStatusResponse: { paymentStatus },
  } = usePaymentStatusContext();
  const renderHeader = () => {
    const status = paymentStatusIdentifier(paymentStatus);
    switch (status) {
      case STATUS.Success:
        return FIELD_NAMES.headingSuccess;
      case STATUS.Referred:
        return FIELD_NAMES.headingReferred;
      case STATUS.Failed:
        return FIELD_NAMES.headingDeclined;
      default:
        return FIELD_NAMES.headingDeclined;
    }
  };
  return (
    <JourneyHeaderComponent>
      <HeadingComponent size="s5" role="none">
        {renderHeader()}
      </HeadingComponent>
    </JourneyHeaderComponent>
  );
}

export default JourneyHeader;
