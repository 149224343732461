import header from 'content/_base/header';
import notfound from 'content/_base/notfound';

import aboutus from './aboutus';
import homepage from './exampleHome';
import { ExampleContent } from '../ExampleAppContent';

const content: ExampleContent = {
  homepage,
  aboutus,
  notfound,
  layoutContent: { ...header },
};

export default content;
