import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { API_ENDPOINTS_CONSTANTS } from '../constants/apiConstants';
import xlbgbrand from '../utils/brand.util';

const axiosClient = axios.create({
  baseURL: API_ENDPOINTS_CONSTANTS.BASE_URL,
  headers: {
     Accept: 'application/json',
    'x-lbg-txn-correlation-id': uuid(),
    'Content-Type': 'application/json',
    'x-lbg-brand': xlbgbrand,
    'x-lbg-channel': 'IB',
    'x-lbg-client-id': API_ENDPOINTS_CONSTANTS.CLIENT_ID
  },
});

axiosClient.interceptors.response.use(
  (response) =>
    //    Use this place to add responsen interceptors handler
    response,
  (error) =>
    // Use this place to add responsen interceptors handler
    // based on error codes. example -> res.status === 401
    Promise.reject(error),
);

export default axiosClient;