/* eslint-disable import/prefer-default-export */
import { Paragraph, Link } from '@constellation/core';
import styled from 'styled-components';

export const StyledParagraph = styled(Paragraph)`
  text-align: center !important;
`;

export const StyledLink = styled(Link)`
  margin-right: 5px;
`;
