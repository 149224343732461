import { useState, useEffect } from 'react';

const useMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const checkViewMode = () => {
    const windowWidth = window.innerWidth;
    const mobileBreakpoint = 541;
    setIsMobile(windowWidth <= mobileBreakpoint);
  };
  useEffect(() => {
    checkViewMode();
    window.addEventListener('resize', () => checkViewMode());
    return () => {
      window.removeEventListener('resize', () => checkViewMode());
    };
  }, []);
  return isMobile;
};

export default useMobile;
