import { ExampleContent } from '../ExampleAppContent';

const aboutus: ExampleContent['aboutus'] = {
  header: 'Our Mission',
  content: `Help colleagues to build and deliver secure, seamless and scalable
                responsive-web channel experiences collaboratively and efficiently.`,
  subContent: `We are an enabler team in Channels Enabling Platform, serving the needs of our
    internal consumers in Platform Teams to create engaging, consistent
    and seamless web journeys impacting 10M+ Internet Banking Customers.`,
  supportHeader: 'Support',
  supportContent: `InterStellar has a Service Desk to provide ongoing support. You can
    use the board to raise tickets for bugs, technical issues, general
    queries, and to suggest or request new features.`,
  supportLink: 'link.',
  supportLinkText:
    'For technical support, queries and requests for new features please follow guidelines for raising service tickets on this',
  sharepointLink:
    'https://lloydsbanking.sharepoint.com/sites/DPRlab/SitePages/Service%20Desk.aspx',
};

export default aboutus;
