import { useCallback, useMemo, useState } from 'react';

import { RequestedPaymentStatusPayload } from '../../interfaces/RequestedPaymentStatus/Requested-payment-status-payload.interface';
import { RequestedPaymentStatusResponse } from '../../interfaces/RequestedPaymentStatus/Requested-payment-status-response.interface';
import { RequestedPaymentStatusContextData } from '../../interfaces/RequestedPaymentStatus/Requested-payment-status.context.interface';
import RequestPaymentStatusService from '../../services/requestPaymentStatus.service';

const useRequestedPaymentStatusContextValue =
  (): RequestedPaymentStatusContextData => {
    const [PaymentStatusResponse, setPaymentStatusResponse] =
      useState<RequestedPaymentStatusResponse>();

    const requestPaymentStatusApi = useCallback(
      async (paymentId: RequestedPaymentStatusPayload) => {
        try {
          const responseStatusDetails =
            await RequestPaymentStatusService.REQUESTED_PAYMENT_STATUS(
              paymentId,
            );
          setPaymentStatusResponse(responseStatusDetails);
        } catch (error) {
          console.log(`Error ${error}`);
        }
      },
      [setPaymentStatusResponse],
    );
    return useMemo(
      () => ({
        requestPaymentStatusApi,
        PaymentStatusResponse,
      }),
      [requestPaymentStatusApi, PaymentStatusResponse],
    );
  };

export default useRequestedPaymentStatusContextValue;
