import React, { ReactElement, useEffect } from 'react';

import { Paragraph, Button, Link } from '@constellation/core';

import { StyleDialog } from './HomePageModal.styled';
import { JourneySteps } from '../../constants/analyticsConstant';
import { Modal } from '../../constants/modalConstant';
import { withContext } from '../../utils/analytics-utilities';
import {
  getBrandTermsAndConditionsLink,
  getBrandDataPrivacyPolicyLink,
} from '../../utils/brand.util';

type HomePageModalProps = {
  onModalClose: () => void;
  isModalOpen: boolean;
  onModalContinueHandler: () => Promise<void>;
};

function HomePageModal({
  onModalClose,
  isModalOpen,
  onModalContinueHandler,
}: HomePageModalProps): ReactElement {

  useEffect(() => {
    const modalElement = document.getElementById("modal");
    modalElement.setAttribute('aria-labelledby','modal');
  },[])

  return (
    <StyleDialog id="modal" open={isModalOpen} onClose={onModalClose} returnFocus>
      <Paragraph>
        {Modal.paragraphOne}{' '}
        <Link
          external
          data-testid="terms"
          href={getBrandTermsAndConditionsLink()}
          onClick={() =>
            // tagging
            withContext(
              JourneySteps.MODAL_RENDER,
              JourneySteps.MODAl_LINK_CLICK_TERMS_AND_CONDITIONS,
            )
          }
        >
          {Modal.termsAndConditions}
        </Link>{' '}
        and{' '}
        <Link
          external
          data-testid="privacy"
          href={getBrandDataPrivacyPolicyLink()}
          onClick={() =>
            // tagging
            withContext(
              JourneySteps.MODAL_RENDER,
              JourneySteps.MODAl_LINK_CLICK_data_privacy_policy,
            )
          }
        >
          {Modal.dataPrivacyPolicy}
        </Link>
      </Paragraph>

      <Paragraph weight="bold">{Modal.paragraphTwo}</Paragraph>
      <Button id='continue' iconPosition="right" onClick={onModalContinueHandler}>
        {Modal.continueButton}
      </Button>
    </StyleDialog>
  );
}

export default HomePageModal;
