import React from 'react';

import ContextProviderComposer from './contextProviderComposer';
import { PaymentRequestorDetailsContext } from './paymentRequestorDetails.context';
import { RequestPaymentStatusContext } from './requestedPaymentStatus.context';
import { SetUpPaymentRequestWithTinkContext } from './setUpPaymentRequestWithTink.context';
import usePaymentDetailsContextValue from '../hooks/paymentRequestorDetails/use-payment-requestor-detail-context-value.hook';
import useRequestedPaymentStatusContextValue from '../hooks/requestedPaymentStatus/requested-payment-status-context-value.hook';
import useSetPaymentRequestTinkContextValue from '../hooks/setPaymentRequestWithTink/set-payment-request-context-value.hook';

// eslint-disable-next-line react/prop-types
function CombinedContextProvider({ children }) {
  const PaymentDetailsContextValue = usePaymentDetailsContextValue();
  const SetPaymentRequestWithTinkContextValue =
    useSetPaymentRequestTinkContextValue();
  const RequestedPaymentStatusContextValue =
    useRequestedPaymentStatusContextValue();
  return (
    <ContextProviderComposer
      contextProviders={[
        <PaymentRequestorDetailsContext.Provider
          value={PaymentDetailsContextValue}
          key="payment_provider"
        />,
        <RequestPaymentStatusContext.Provider
          value={RequestedPaymentStatusContextValue}
          key="payment_status_provider"
        />,
        <SetUpPaymentRequestWithTinkContext.Provider
          value={SetPaymentRequestWithTinkContextValue}
          key="Set_Payment_Request_With_Tink"
        />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
}

export default CombinedContextProvider;
