import React, { ReactElement } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';

import content from '../../content';
import CombinedContextProvider from '../../context/combinedContextProvider';
import Routes from '../../routes';
import { getThemeFromUrl } from '../../utils/getThemeFromUrl';
import useAppConfig from '../appConfig/useAppConfig';
import AppLayout from '../appLayout/Applayout';
import BrandProvider, { Brand } from '../brandProvider';

function App(): ReactElement {
  const configBrand = useAppConfig().BRAND_NAME;
  const brand: Brand = getThemeFromUrl() || configBrand;

  return (
    <CombinedContextProvider>
      <BrandProvider brand={brand}>
        <ContentProvider value={content({ brand }).layoutContent}>
          <AppLayout>
            <Routes />
          </AppLayout>
        </ContentProvider>
      </BrandProvider>
    </CombinedContextProvider>
  );
}

export default App;
