import React, { ReactElement, useEffect, useRef, useState } from 'react';

import {
  CurrencyField,
  TextField,
  Notification,
  Hr,
  Heading,
  Text,
} from '@constellation/core';


import { StyledButton } from './HomePage.styled';
import HomePageModal from './HomePageModal';
import { IUserStateInterface } from './userState.inteface';
import Loader from '../../components/loader/Loader';
import { JourneySteps } from '../../constants/analyticsConstant';
import { ERROR } from '../../constants/errorConstants';
import { FIELD_NAMES, INITIAL_ERROR_DATA } from '../../constants/pageConstants';
import { AMOUNT_ERROR_MSG } from '../../constants/validationErrorMsg';
import usePaymentDetailsContext from '../../hooks/paymentRequestorDetails/use-payment-requestor-details-context-hooks';
import useSetPaymentRequestTinkContext from '../../hooks/setPaymentRequestWithTink/set-payment-request-context-hook';
import {
  sendEvent,
  setAnalyticsPageEvent,
  withContext,
  sendFieldUpdateEvent,
} from '../../utils/analytics-utilities';
import { redirectToErrorPage } from '../../utils/redirectToErrorPage';
import useMobile from '../../utils/useIsMobileUtils';
import {
  amountFieldValidation,
  referenceFieldValidation,
  formatFixed2DecimalPlace,
} from '../../utils/validation';
import { useAppLayoutContext } from '../../context/AppLayoutContext';

const fieldFocusTime = {};
function HomePage(): ReactElement {
  const searchParams = new URLSearchParams(window.location.search);
  const {isTnCModalOpen,setTnCModalContextValue} = useAppLayoutContext();
  const abbreviation = searchParams.get(FIELD_NAMES.abbrevationURLKeyName);
  const amnt = searchParams.get(FIELD_NAMES.amountURLKeyName);
  const ref = searchParams.get(FIELD_NAMES.referenceURLKeyName);
  const paramReference = ref?.slice(0, 18);
  const paramAmount = amnt && parseFloat(amnt).toFixed(2);

  const { PaymentDetailResponse, isLoading, PaymentDetailsApi } =
    usePaymentDetailsContext();
  const initialValue = {
    reference: paramReference || '',
    amount: paramAmount || '',
  } as IUserStateInterface;
  const [userDetail, setUserDetail] =
    useState<IUserStateInterface>(initialValue);
  const [amountError, setAmountError] = useState<string>(
    INITIAL_ERROR_DATA.amountError,
  );
  const [referenceError, setReferenceError] = useState<string>(
    INITIAL_ERROR_DATA.referenceError,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [continueDisabled, setContinueDisabled] = useState<boolean>(false);
  const { SetPayemnetWithTink, setupPaymentResponse, loadingSetupPaymentRequest,setLoading:toggleSetupPaymentLoader } =
    useSetPaymentRequestTinkContext();
  const isMobile = useMobile();
  const buttonRef = useRef<HTMLButtonElement>(null);
  
  useEffect(() => {
    if (
      abbreviation === null ||
      abbreviation === '' ||
      abbreviation === undefined
    ) {
      redirectToErrorPage();
    }

    PaymentDetailsApi({ abbreviation });
    
    const errorRef: string = referenceFieldValidation(paramReference);
    const errorAmnt: string = amountFieldValidation(paramAmount);
    if(paramReference) setReferenceError(errorRef);
    if (paramAmount) setAmountError(errorAmnt);
    setContinueDisabled(Boolean(errorRef || errorAmnt));
  }, [PaymentDetailsApi, abbreviation, paramReference, paramAmount]);

  useEffect(() => {
    if (PaymentDetailResponse) {
      const payeeName: string = PaymentDetailResponse.payeeName.slice(0, 18) || ''
      const errorRef: string = !paramReference ? referenceFieldValidation(payeeName):referenceError;
      const errorAmnt: string = amountFieldValidation(paramAmount);
      if (paramAmount) setAmountError(errorAmnt);
      setContinueDisabled(Boolean(errorRef || errorAmnt));
      if (!paramReference) {
        setReferenceError(errorRef);
        setUserDetail((prevState) => ({
          ...prevState,
          reference: payeeName,
        }));
      }
    }
  }, [PaymentDetailResponse, paramAmount])

  useEffect(() => {
    if (setupPaymentResponse?.tinkRedirectUrl) {
      window.location.href = setupPaymentResponse.tinkRedirectUrl;
    }
    if (setupPaymentResponse?.code === ERROR.EXCEED_DAILY_LIMIT_CODE) {
      setAmountError(AMOUNT_ERROR_MSG.maximumAmountInDay);
      toggleSetupPaymentLoader(false)
      setIsModalOpen(false);
    }
    setAnalyticsPageEvent(
      // tagging
      '1',
      'Send money to',
      ['', '[Insert inline validation message]', 'Application'],
      'Payments',
      '[TBC]',
    );
  }, [setupPaymentResponse]);

  const dwellTime = (event) => Date.now() - fieldFocusTime[event.target.id];

  const handleFieldFocus = (event) => {
    fieldFocusTime[event.target.id] = Date.now();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handleFieldFocus(event);
    const { name, value } = event.target;
    const errorRef: string = name === FIELD_NAMES.referenceName ? referenceFieldValidation(value) : referenceFieldValidation(userDetail.reference);
    const errorAmnt: string = name === FIELD_NAMES.amountName ? amountFieldValidation(value) : amountFieldValidation(userDetail.amount);
    setAmountError(errorAmnt);
    setReferenceError(errorRef);
    setUserDetail({ ...userDetail, [name]: value });
    setContinueDisabled(Boolean(errorRef || errorAmnt));
  };


  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const errorRef: string = name === FIELD_NAMES.referenceName ? referenceFieldValidation(value) : referenceFieldValidation(userDetail.reference);
    const errorAmnt: string = name === FIELD_NAMES.amountName ? amountFieldValidation(value) : amountFieldValidation(userDetail.amount);
    if (name === 'amount') {
      const amount = value ? formatFixed2DecimalPlace(value) : '';
      setUserDetail({ ...userDetail, amount });
      setAmountError(errorAmnt);
      sendFieldUpdateEvent('Amount', dwellTime(event), errorAmnt); // tagging
    } else if (name === 'reference') {
      sendFieldUpdateEvent('Reference', dwellTime(event), errorRef); // tagging
    }
    setReferenceError(errorRef);
    setContinueDisabled(Boolean(errorRef || errorAmnt));
  };

  const onModalContinueHandler = async (): Promise<void> => {
    setIsModalOpen(false)
    const { reference, amount: inputAmount } = userDetail;
    const errorAmnt: string = referenceFieldValidation(reference);
    const errorRef: string = amountFieldValidation(inputAmount);
    const amount = parseFloat(inputAmount)
    const requestorBody = { abbreviation, amount, reference }
    if (!errorAmnt && !errorRef) {
      SetPayemnetWithTink({ ...requestorBody });
    }
    withContext(JourneySteps.MODAL_RENDER, JourneySteps.MODAL_CONTINUE); // tagging
  };

  const onContinueToModal = () => {
    buttonRef.current = document.activeElement as HTMLButtonElement;
    setIsModalOpen(true);
    setTnCModalContextValue(true); //this is the context value updation
    sendEvent({ JourneyEvent: 'Button Click', LinkValue: 'button/continue' }); // tagging
    withContext(JourneySteps.MODAL_RENDER); // tagging
  };

  const onModalClose = () => {
    withContext(JourneySteps.MODAL_CLOSE, JourneySteps.MODAL_CLOSE_VALUE);
    setIsModalOpen(false);
    setTnCModalContextValue(false);
  };

  return isLoading || loadingSetupPaymentRequest ? (
    <Loader />
  ) : (
    <>
    <div aria-hidden={isTnCModalOpen}>
      <Text size="s2" weight="bold">
        {FIELD_NAMES.Label}
      </Text>
      <Heading as="h1" size="s5" color="brand" marginBottom="07">
        {PaymentDetailResponse?.payeeName}
      </Heading>
      <TextField
        inputAttributes={{ 'data-testid': 'reference' }}
        label={FIELD_NAMES.referenceLabel}
        name={FIELD_NAMES.referenceName}
        value={userDetail.reference}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFieldFocus}
        error={referenceError}
        maxLength={18}
        aria-label={FIELD_NAMES.referenceAriaLabel}
        inputWidth={isMobile ? 'fluid' : 'default'}
      />
      <CurrencyField
        type='number'
        label={FIELD_NAMES.amountLabel}
        name={FIELD_NAMES.amountName}
        value={userDetail.amount}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFieldFocus}
        error={amountError}
        maxLength={6}
        aria-label={FIELD_NAMES.amountAriaLabel}
        inputWidth={isMobile ? 'fluid' : 'default'}
      />
      <Hr aria-hidden="true" />
      <div>
      <StyledButton
        data-testid="continue"
        id='continue-button'
        iconPosition="right"
        onClick={onContinueToModal}
        disabled={continueDisabled}
        ref={buttonRef}
      >
        {FIELD_NAMES.buttonLabel}
      </StyledButton>
      </div>
      <Notification
        marginBottom="06"
        heading={FIELD_NAMES.disclaimerBoxHeading}
        headingLevel={2}
        sentiment="information"
      >
        {FIELD_NAMES.disclaimerBoxParagraph}
      </Notification>
    </div>

      {isModalOpen && (
        <HomePageModal
          isModalOpen={isModalOpen}
          onModalClose={onModalClose}
          onModalContinueHandler={onModalContinueHandler}
        />
      )}
    </>
  );
}

export default HomePage;
