import { Grid, Text } from '@constellation/core';
import styled from 'styled-components';

export const FooterGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  padding: 0px 1.5rem;
  color: ${({ theme }) => theme.color_text_inverse_default_1};
`;

export const TextComponent = styled(Text)`
  margin: 0;
  text-align: left;
  padding-top: 12px;
`;
