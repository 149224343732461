import React, { ReactElement } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { Routes, Route } from '@interstellar/react-app-routing';
import ErrorPage from 'components/error/ErrorPage';

import HomePage from './homePage/HomePage';
import * as routes from './manifest';
import NotFound from './notFound/NotFound';
import useAppConfig from '../components/appConfig/useAppConfig';
import SummaryPage from '../components/summary/SummaryPage';
import content from '../content';
import ExampleRoutes from '../examples/exampleRoutes';
import { getThemeFromUrl } from '../utils/getThemeFromUrl';

function App(): ReactElement {
  const brandConfig = useAppConfig().BRAND_NAME;
  const brand = getThemeFromUrl() || brandConfig;

  return (
    <Routes>
      <Route
        path={routes.Home}
        element={
          <ContentProvider value={content({ brand }).homepage}>
            <HomePage />
          </ContentProvider>
        }
      />
      <Route path={routes.Examples} element={<ExampleRoutes />} />
      <Route
        path="*"
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <NotFound />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Outcome}
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <SummaryPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ErrorRoute}
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <ErrorPage />
          </ContentProvider>
        }
      />
    </Routes>
  );
}

export default App;
