/*This file is was created to track any activity on top notch node of app,
 especially to handle accessibility part of Modal overlay.*/
import { ReactNode, createContext, useContext, useState } from 'react';

//interface for AppLayout Context
interface AppLayoutContextType{
    isTnCModalOpen:boolean,
    setTnCModalContextValue:(isTnCModalOpen:boolean)=>void
}

//interface for AppLayout Provider
interface AppLayoutProviderProps{
    children:ReactNode
}

const AppLayoutContext = createContext<AppLayoutContextType|null>(null);

//Custom hook created from teh default context
export const useAppLayoutContext = ()=> {
    const context = useContext(AppLayoutContext);
    if (!context) {
        throw new Error(
          `useAppLayoutContext be used within useAppLayoutContext.Provider `
        );
    }
    return context;
}

//Provider for the AppLayout context
export const AppLayoutProvider: React.FC<AppLayoutProviderProps> = ({children}) => {
    const [isTnCModalOpen, setTnCModalContextValue]=useState<boolean>(false)
    return(
        <AppLayoutContext.Provider value={{isTnCModalOpen, setTnCModalContextValue}}>
            {children}
        </AppLayoutContext.Provider>
    )
}