export const isDevelopment = process.env.NODE_ENV === 'development'

const MOCK_SERVER = 'http://localhost:3000/request-a-payment' ;
const STATIC_CLIENT_ID = 'q1VItQAPeYjeZ6hglsFkudMkB0BhKkd0Xqa41m9wG9oVGIOG' ;

const API_INFO = !isDevelopment && JSON.parse(window.appConfig.API_ENDPOINT);

export const API_ENDPOINTS_CONSTANTS = {
  PAYMENT_REQUESTOR_DETAILS: 'payee-details/v1/payment-requestor-details',
  SET_UP_PAYMENT_REQUEST_WITH_TINK: 'setup-payment-request/v1/set-up-payment-request',
  REQUESTED_PAYMENT_STATUS: 'setup-payment-request/v1/payment-status',
  ABUSIVE_REFERENCE_CHECKER: 'reference-check/v1/payee-reference-check',
  BASE_URL: isDevelopment 
  ? MOCK_SERVER
  : `${API_INFO[window.appConfig.BRAND_NAME]}/request-a-payment/`,
  CLIENT_ID: isDevelopment 
  ? STATIC_CLIENT_ID
  : window.appConfig.CLIENT_ID,
};
