import React, { ReactElement } from 'react';

import { Footer } from '@constellation/core';

import { FooterGrid, TextComponent } from './FooterComponent.styled';
import dataQaIds from '../../examples/dataModel/dataQaIds';
import { getBrandFooter } from '../../utils/brand.util';
import { useAppLayoutContext } from '../../context/AppLayoutContext';

export default function FooterComponent(): ReactElement {
  const {isTnCModalOpen} = useAppLayoutContext();
  return (
    <Footer marginTop="03" data-qa-id={dataQaIds.footer.container} aria-hidden={isTnCModalOpen}>
      <FooterGrid alignX="center">
        <TextComponent role='listitem' tabIndex={0}>
          {getBrandFooter()}
        </TextComponent>
      </FooterGrid>
    </Footer>
  );
}
