import { useCallback, useMemo, useState } from 'react';

import { IPaymentRequestorDetailsPayload } from '../../interfaces/PaymentRequestorDetails/Payment-requestor-detail-payload.interface';
import { IPaymentRequestorDetailResponse } from '../../interfaces/PaymentRequestorDetails/Payment-requestor-detail-response.interface';
import { IPaymentDetailsContextData } from '../../interfaces/PaymentRequestorDetails/Payment-requestor-details.context.interface';
import PaymentRequestorService from '../../services/paymentRequestorDetails.service';

const usePaymentDetailsContextValue = (): IPaymentDetailsContextData => {
  const [PaymentDetailResponse, setPaymentDetailsResponse] =
    useState<IPaymentRequestorDetailResponse>();
  const [isLoading, setLoading] = useState(false);

  const PaymentDetailsApi = useCallback(
    async (abbreviation: IPaymentRequestorDetailsPayload) => {
      try {
        setLoading(true);
        const payeeName =
          await PaymentRequestorService.PAYMENT_REQUESTOR_DETAILS(abbreviation);
        setPaymentDetailsResponse(payeeName);
        setLoading(false);
      } catch (error) {
        console.log(`Error ${error}`);
      }
    },
    [setPaymentDetailsResponse, setLoading],
  );
  return useMemo(
    () => ({
      PaymentDetailsApi,
      PaymentDetailResponse,
      isLoading,
      setPaymentDetailsResponse,
      setLoading,
    }),
    [
      PaymentDetailsApi,
      isLoading,
      PaymentDetailResponse,
      setPaymentDetailsResponse,
      setLoading,
    ],
  );
};

export default usePaymentDetailsContextValue;
