import { ContentGroup, GridItem, } from '@constellation/core';
import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: 269.993px;
  @media only screen and (max-width: 541px) {
    width: 360px;
    padding: 0 12px;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  @media only screen and (max-width: 541px) {
    gap: 24px;
  }
`;

export const GridItemContainer = styled(GridItem)`
  display: flex;
  width: 643px !important;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 541px) {
    flex: 1 0 0;
  }
`;

export const ContentGroupDiv = styled(ContentGroup)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
