import { STATUS } from "../constants/pageConstants";

const paymentStatusIdentifier = (paymentStatus: string) => {
  switch (paymentStatus) {
    case STATUS.Paid:
      return STATUS.Success;
    case STATUS.Referred:
      return STATUS.Referred;
    case STATUS.Cancelled:
    case STATUS.Failed:
      return STATUS.Failed;
    default:
      return STATUS.Failed;
  }
};

export default paymentStatusIdentifier;
