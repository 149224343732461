import { useContext } from 'react';

import { PaymentRequestorDetailsContext } from '../../context/paymentRequestorDetails.context';

const usePaymentDetailsContext = () => {
  const PaymentDetailsContext = useContext(PaymentRequestorDetailsContext);
  if (!PaymentDetailsContext) {
    throw new Error(
      `usePaymentDetailsContext be used within usePaymentDetailsContext.Provider `,
    );
  }
  return PaymentDetailsContext;
};

export default usePaymentDetailsContext;
