import React from 'react';

import { Page, Main } from '@constellation/core';
import { useLocation } from '@interstellar/react-app-routing';

import LayoutProps from './Applayout.types';
import dataQaIds from '../../examples/dataModel/dataQaIds';
import Footer from '../footer/FooterComponent';
import Header from '../header/HeaderComponent';
import { AppLayoutProvider } from '../../context/AppLayoutContext';

function AppLayout({ children }: LayoutProps): React.JSX.Element {
  const location = useLocation();
  const isShowingFooter = location.pathname !== '/error';

  return (
    <Page>
      <AppLayoutProvider>
      <Header data-qa-id={dataQaIds.header.container} />
      <Main>{children}</Main>
      {isShowingFooter && <Footer />}
      </AppLayoutProvider>
    </Page>
  );
}

export default AppLayout;
