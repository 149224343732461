import React, { ReactElement } from 'react';

import { Heading } from '@constellation/core';

import { Span } from './SummaryPage.styled';
import { FIELD_NAMES, STATUS } from '../../constants/pageConstants';
import { usePaymentStatusContext } from '../../hooks/requestedPaymentStatus/request-payment-status-context-hooks';

function SummaryDetailsTitle(): ReactElement {
  const {
    PaymentStatusResponse: { paymentStatus },
  } = usePaymentStatusContext();

  return (
    <>
      <Span className="paddingClass" />
      <Heading
        size="s3"
        marginBottom="none"
        aria-level={3}
        aria-label={`${
          paymentStatus === STATUS.Paid
            ? FIELD_NAMES.paymentDetails
            : FIELD_NAMES.details
        } summary`}
      >
        {paymentStatus === STATUS.Paid
          ? FIELD_NAMES.paymentDetails
          : FIELD_NAMES.details}
      </Heading>
    </>
  );
}

export default SummaryDetailsTitle;
