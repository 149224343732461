export const lbgAnalyticsInstance = () =>
  // set a instance and used in below functions
  new Promise((resolve, reject) => {
    if (window && window.runAnalytics) {
      resolve(window.runAnalytics);
    } else {
      reject(new Error('UTAG not loaded'));
    }
  });

export const sendFieldUpdateEvent = (field, dwell, validationMessage) =>{
  if (window && window.runAnalytics) {
    window.runAnalytics((LBGAnalytics) => {
      LBGAnalytics.events
      .fieldUpdate(field, dwell, validationMessage).send();
    });
  }
}

export const sendEvent = (eventData) =>
  // this for events like button
  lbgAnalyticsInstance()
    .then((instance: any) => instance.events.send(eventData))
    .catch((err) => console.log(err));

export const setAnalyticsPageEvent = (
  // This function is for setting initial page load tagging.
  stepNumber: string,
  pageName: string,
  journeyStatus: string[],
  JourneyProduct: string,
  setApplicationId: string,
) => {
  if (window && window.runAnalytics) {
    window.runAnalytics((LBGAnalytics) => {
      LBGAnalytics.data
        .setJourneyStep(stepNumber, pageName)
        .setJourneyStatus(...journeyStatus)
        .setJourneyProduct(JourneyProduct)
        .setApplicationId(setApplicationId)
        .done()
        .events.pageView()
        .send();
    });
  }
};

export const withContext = (data: {}, data2?: {}) =>
  // this for modal and their values
  lbgAnalyticsInstance()
    .then((instance: any) => {
      if (data2) {
        return instance.events.withContext(data).send(data2);
      }
      return instance.events.withContext(data).pageView().send();
    })
    .catch((err) => console.log(err));

export const apiError = (message) =>
  // for api error
  lbgAnalyticsInstance()
    .then((instance: any) => instance.events.apiError(message).send())
    .catch((err) => console.log(err));

export default {
  withContext,
  lbgAnalyticsInstance,
  sendFieldUpdateEvent,
  sendEvent,
  setAnalyticsPageEvent,
  apiError,
};
