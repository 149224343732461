import SetPaymentWithTinkService from '../services/setPaymentRequest.service';
import regEx from '../constants/regEx';
import {
  AMOUNT_ERROR_MSG,
  REFERENCE_ERROR_MESSAGE,
} from '../constants/validationErrorMsg';

export const amountFieldValidation = (value: string): string => {
  const { emptyAmountErrorMessage, maximumAmountOneTime, nonNegativeAmountErrorMessage } = AMOUNT_ERROR_MSG;

  const numvalue = Number(value);
  const invalidAmount = value !== undefined && Number.isNaN(numvalue);
  const isZero = value !== '' && numvalue === 0;
  const isNegative = numvalue < 0;
  const isEmpty = value === '';

  if (invalidAmount || isZero || isEmpty) {
    return emptyAmountErrorMessage;
  }

  if(isNegative) {
    return nonNegativeAmountErrorMessage;
  }

  if (numvalue > 150) {
    return maximumAmountOneTime;
  }

  return '';
};

export const formatFixed2DecimalPlace = (value: string): string =>
  parseFloat(value).toFixed(2);

export const AbusiveReferenceValidation = async (value:string): Promise<{valid:boolean}>=> {
  const response = await SetPaymentWithTinkService.ABUSIVE_REFERENCE_CHECKER(value)
  return response;
}

export const referenceFieldValidation = (value: string): string => {
  const { invalidReference, noReference } = REFERENCE_ERROR_MESSAGE;
  if(value === '') return noReference;
  if(!regEx.referenceRegex.test(value)) {
    return invalidReference;
  }
  return '';
};

