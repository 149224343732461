import { useContext } from 'react';

import { SetUpPaymentRequestWithTinkContext } from '../../context/setUpPaymentRequestWithTink.context';

const useSetPaymentRequestTinkContext = () => {
  const useSetPaymentRequestTink = useContext(
    SetUpPaymentRequestWithTinkContext,
  );
  if (!useSetPaymentRequestTink) {
    throw new Error(
      `useSetPaymentRequestTink be used within useSetPaymentRequestTink.Provider `,
    );
  }
  return useSetPaymentRequestTink;
};

export default useSetPaymentRequestTinkContext;
