import React, { ReactElement } from 'react';

import {
  TickComponent,
  LogoComponent,
  LogoComponentDeclined,
  LogoComponentReferredHalifax,
  LogoComponentReferred,
} from './SummaryPage.styled';
import { STATUS } from '../../constants/pageConstants';
import { usePaymentStatusContext } from '../../hooks/requestedPaymentStatus/request-payment-status-context-hooks';
import xlbgbrand from '../../utils/brand.util';
import paymentStatusIdentifier from '../../utils/payments.util';

function StatusLogo(): ReactElement {
  const {
    PaymentStatusResponse: { paymentStatus },
  } = usePaymentStatusContext();
  const brand = xlbgbrand;

  const renderLogo = () => {
    const status = paymentStatusIdentifier(paymentStatus);
    switch (status) {
      case STATUS.Success:
        return <LogoComponent color="success" data-testid="success-logo" />;
      case STATUS.Referred:
        return (
          (brand === 'halifax' && (
            <LogoComponentReferredHalifax
              color="warning"
              data-testid="inreview-logo-halifax"
            />
          )) || (
            <LogoComponentReferred
              color="warning"
              data-testid="inreview-logo"
            />
          )
        );
      case STATUS.Failed:
        return (
          <LogoComponentDeclined color="critical" data-testid="declined-logo" />
        );
      default:
        return '';
    }
  };

  return (
    <TickComponent
      role="img"
      aria-label="Mark as Decorative"
      tabIndex={0}
      data-testid="tick"
    >
      {renderLogo()}
    </TickComponent>
  );
}

export default StatusLogo;
