import { useContext } from 'react';

import { RequestPaymentStatusContext } from '../../context/requestedPaymentStatus.context';

export const usePaymentStatusContext = () => {
  const useRequestedPaymentStatusContext = useContext(
    RequestPaymentStatusContext,
  );

  if (!useRequestedPaymentStatusContext) {
    throw new Error(
      `usePaymentStatusContext be used within usePaymentStatusContext.Provider`,
    );
  }
  return useRequestedPaymentStatusContext;
};
