/* eslint-disable import/prefer-default-export */
import { Button, Link } from '@constellation/core';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  color: #000 !important;
  display: block !important;
  padding-top: 5px !important;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 36px;
`;
