import {
  getBrandTermsAndConditionsLink,
  getBrandDataPrivacyPolicyLink,
} from '../utils/brand.util';

export const JourneySteps = {
  MODAL_RENDER: {
    JourneyStepName: 'Terms and Conditins',
    JourneyAction: 'More Information Needed',
  },

  MODAl_LINK_CLICK_TERMS_AND_CONDITIONS: {
    JourneyEvent: 'External Click',
    EventAction: 'Offsite Link',
    EventNarrative: 'Link Pay terms and conditions',
    ResourceFilename: getBrandTermsAndConditionsLink(),
  },

  MODAl_LINK_CLICK_data_privacy_policy: {
    JourneyEvent: 'External Click',
    EventAction: 'Offsite Link',
    EventNarrative: 'data privacy policy',
    ResourceFilename: getBrandDataPrivacyPolicyLink(),
  },

  MODAL_CONTINUE: { LinkValue: 'Terms and Conditins/Continue' },

  MODAL_CLOSE: {
    JourneyStepName: 'Terms and Conditins',
    JourneyAction: 'Rejected Terms and Conditions',
  },
  MODAL_CLOSE_VALUE: { LinkValue: 'ModalName/X' },
};
