import { getThemeFromUrl } from '../../utils/getThemeFromUrl';
import _base from '../_base';
import { AppContent } from '../AppContent';

const { BRAND_NAME } = (window as any).appConfig;
const brand = getThemeFromUrl() || BRAND_NAME;

/* eslint no-param-reassign: "error" */
if (brand === 'scottish-widows') {
  _base.homepage.brandName = 'Scottish-Widows';
}
const content: AppContent = {
  ..._base,
  ..._base.homepage,
};

export default content;
