import React, { ReactElement } from 'react';

import { Grid, GridItem } from '@constellation/core';

import {
  PaymentDetails,
  Content,
  HeadingName,
  HeadingValue,
  PaymentSubDetails,
} from './SummaryPage.styled';
import {
  FIELD_NAMES,
  PAYMENT_OUTCOME,
  STATUS,
} from '../../constants/pageConstants';
import { usePaymentStatusContext } from '../../hooks/requestedPaymentStatus/request-payment-status-context-hooks';
import Capitalize from '../../utils/string.util';
import { formatFixed2DecimalPlace } from '../../utils/validation';

function PaymentDetailsComponent(): ReactElement {
  const {
    PaymentStatusResponse: {
      payeeName,
      amount,
      reference,
      payerBankName,
      paymentStatus,
    },
  } = usePaymentStatusContext();
  const pound = '\u00A3';

  const renderCapitalizedname = (name: string) => Capitalize(name);
  const poundValue = `${pound}${formatFixed2DecimalPlace(amount.toString())}`

  return (
    <Grid>
      <PaymentDetails>
        <PaymentSubDetails>
          <Content>
            <GridItem sm={12}>
              <HeadingName>
                {paymentStatus && paymentStatus === STATUS.Paid
                  ? PAYMENT_OUTCOME.youPaid
                  : PAYMENT_OUTCOME.to}
                <span aria-hidden="true">:</span>
              </HeadingName>
            </GridItem>
            <GridItem sm={12}>
              <HeadingValue size="s2" weight="bold">
                {renderCapitalizedname(payeeName)}
              </HeadingValue>
            </GridItem>
          </Content>
          <Content>
            <GridItem sm={12}>
              <HeadingName>
                {FIELD_NAMES.reference}
                <span aria-hidden="true">:</span>
              </HeadingName>
            </GridItem>
            <GridItem sm={12}>
              <HeadingValue size="s2" weight="bold">
                {`'${reference}'`}
              </HeadingValue>
            </GridItem>
          </Content>
          <Content>
            <GridItem>
              <HeadingName>
                {FIELD_NAMES.amount}
                <span aria-hidden="true">:</span>
              </HeadingName>
            </GridItem>
            <GridItem>
              <HeadingValue size="s2" weight="bold">
                {poundValue}
              </HeadingValue>
            </GridItem>
          </Content>
          <Content>
            <GridItem>
              <HeadingName>
                {paymentStatus && paymentStatus === STATUS.Paid
                  ? FIELD_NAMES.bank_label
                  : FIELD_NAMES.bank}
                <span aria-hidden="true">:</span>
              </HeadingName>
            </GridItem>
            <GridItem>
              <HeadingValue size="s2" weight="bold">
                {payerBankName}
              </HeadingValue>
            </GridItem>
          </Content>
        </PaymentSubDetails>
      </PaymentDetails>
    </Grid>
  );
}

export default PaymentDetailsComponent;
