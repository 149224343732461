export const FIELD_NAMES = {
  Label: 'Send money to',
  referenceLabel: 'Reference',
  amountLabel: 'Amount',
  referenceName: 'reference',
  amountName: 'amount',
  disclaimerBoxHeading: `Not sure what this is for?`,
  disclaimerBoxParagraph: `If you weren't expecting it or something doesn't feel right, contact this person first to make sure it is not a scam.`,
  buttonLabel: 'Continue',
  accordionLabel: 'This service uses Open banking',
  headingSuccess: 'Payment requested',
  headingDeclined: 'Payment not made',
  headingReferred: 'Payment under review',
  confirmName: 'Your payment has been sent',
  declined: 'Your payment has not been sent',
  details: 'Details',
  paymentDetails: 'Payment details',
  reference: 'Reference',
  referenceDeclined: 'We have not made your payment',
  amount: 'Amount',
  bank: 'From',
  bank_label: 'From your bank',
  closeMessage: 'You can now close this page',
  exitMessage: 'You can now exit this screen',
  referred: "It looks like your bank hasn't released this payment yet",
  abbrevationURLKeyName: 'a',
  amountURLKeyName: 'amt',
  referenceURLKeyName: 'ref',
  paymentIdKeyName: 'payment_request_id',
  referenceAriaLabel: 'Add Reference',
  amountAriaLabel: 'Add Amount',
};

export const STATUS = {
  Success: 'success',
  Failed: 'Failed',
  Referred: 'partiallySent',
  Paid: 'Paid',
  Cancelled: 'Cancelled'
};

export const PAYMENT_OUTCOME = {
  disclaimerSuccessHeading: 'What happens next',
  disclaimerInReviewHeading: 'Next steps',
  disclaimerHeading: 'What to do next',
  disclaimerListItem1:
    'try to make this payment again using the link or QR code provided.',
  disclaimerListItem2:
    'contact your bank or building society for help with this payment.',
  disclaimerReferredListItem1: "Don't try to make the payment again",
  disclaimerReferredListItem2:
    'Check your account over the next two hours to see if the payment goes out',
  disclaimerReferredListItem3:
    "If it doesn't, contact your bank or nuilding society for help",
  to: 'To',
  youPaid: 'You Paid',
  disclaimerMessageSuccess:
    "This payment should be sent and received within two hours. If it isn't, contact your bank or building society for help.",
  disclaimerSubheadingFailed: 'You can either:',
};

export const INITIAL_ERROR_DATA = {
  amountError: '',
  referenceError: '',
};

export const disclaimerHelperNotes = [
  {
    id: 1,
    paymentStatus: STATUS.Success,
    oneLiner: PAYMENT_OUTCOME.disclaimerMessageSuccess,
  },
  {
    id: 2,
    paymentStatus: STATUS.Failed,
    subHeading: PAYMENT_OUTCOME.disclaimerSubheadingFailed,
    bulletPoints: [
      PAYMENT_OUTCOME.disclaimerListItem1,
      PAYMENT_OUTCOME.disclaimerListItem2,
    ],
  },
  {
    id: 3,
    paymentStatus: STATUS.Referred,
    bulletPoints: [
      PAYMENT_OUTCOME.disclaimerReferredListItem1,
      PAYMENT_OUTCOME.disclaimerReferredListItem2,
      PAYMENT_OUTCOME.disclaimerReferredListItem3,
    ],
  },
];
