import React, { ReactElement } from 'react';

import {
  Container,
  Grid,
  GridItem,
  useWindowSize,
  pxToRem,
  BackgroundProvider,
} from '@constellation/core';
import { NavLink } from '@interstellar/react-app-routing';
import { useTheme } from 'styled-components';

import { StyledHeader } from './HeaderComponent.styled';
import * as routes from '../../routes/manifest';
import { getAriaLabel } from '../../utils/brand.util';
import { useAppLayoutContext } from '../../context/AppLayoutContext';

export default function HeaderComponent(): ReactElement {
  const theme: any = useTheme();

  const { width: windowWidth } = useWindowSize();

  const isNarrow = pxToRem(windowWidth) < parseFloat(theme.breakpoint_lg);
  const isWide = !isNarrow;

  const Logo = isWide ? theme.assets.logo.wide : theme.assets.logo.base;
  const {isTnCModalOpen} = useAppLayoutContext();


  return (
    <StyledHeader aria-hidden={isTnCModalOpen}>
      <BackgroundProvider value={{ cssValue: theme.header_color_background }}>
        <Container>
          <Grid alignY="center">
            <GridItem xs={4}>
              <NavLink
                to={routes.Home}
                title="logo"
                aria-label={getAriaLabel()}
              >
                <Logo
                  style={{
                    height: isWide
                      ? theme.header_logo_height_big
                      : theme.header_logo_height,
                    width: isWide
                      ? theme.header_logo_width_big
                      : theme.header_logo_width,
                  }}
                />
              </NavLink>
            </GridItem>
          </Grid>
        </Container>
      </BackgroundProvider>
    </StyledHeader>
  );
}
