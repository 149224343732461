export const ERROR = {
  HEADING: 'Sorry, something went wrong',
  SUB_HEADING:
    "This is due to an issue on our side. It shouldn't take long to fix.",
  DISCLAIMER_CONTENT:
    'Optional message body. This is custom text for each journey scenario and is supplied by UX Writer. Apply brand voice and tone. Content structure and length comply with platform constraints and editorial style standards and conventions.',
  MESSAGE: 'Please try again later.',
  EXCEED_DAILY_LIMIT_CODE: "400006",
  ABUSIVE_REFERENCE_DETECTED: "You can't make a payment with this reference"
};
