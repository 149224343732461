/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/no-extraneous-dependencies */
import 'core-js/stable';
import React from 'react';

import { BrowserRouter } from '@interstellar/react-app-routing';
import { createRoot } from 'react-dom/client';

import App from './components/app/App';
import AppConfigContext from './components/appConfig/AppConfigContext';
import xlbgbrand from './utils/brand.util';

const mount = (containerElement, customProps = {}) => {
  const root = createRoot(containerElement);
  // eslint-disable-next-line no-console
  console.log('customProps: ', customProps);
  root.render(
    <AppConfigContext.Provider value={(window as any).appConfig}>
      <BrowserRouter basename={process.env.BASE_PATH}>
        <App />
      </BrowserRouter>
    </AppConfigContext.Provider>,
  );
};

window.runAnalytics((LBGAnalytics) => {
  LBGAnalytics.data
    .setJourney('PaymentRequest', undefined)
    .setBrand(xlbgbrand)
    .setDivision('Retail')
    .setPresentation('Responsive')
    .setProduct('Service', 'Payments')
    .setChannel('Online', undefined, undefined, false)
    .setPageRole('Servicing')

    .setJourneyProduct('Payments', undefined, undefined)
    .setApplicationId('[TBC]');
});

const container = document.getElementById('app');

if (process.env.NODE_ENV === 'development' || container) {
  if (container) {
    mount(container, { isRemote: false });
  }
}

export { mount };
