export const REFERENCE_ERROR_MESSAGE = {
  invalidReference: `Please use only letters and numbers`,
  noReference: `Please enter a reference for this payment`,
};

export const AMOUNT_ERROR_MSG = {
  emptyAmountErrorMessage: `Please enter an amount `,
  nonNegativeAmountErrorMessage: `Please enter a valid amount`,
  maximumAmountOneTime: `The maximum you can pay is £150`,
  maximumAmountInDay: `Sorry, this would take them over their daily Link Pay limit. Please try again tomorrow.`,
};
