import { API_ENDPOINTS_CONSTANTS } from '../constants/apiConstants';
import axiosClient from '../factories/axios.client.utils';
import { RequestedPaymentStatusPayload } from '../interfaces/RequestedPaymentStatus/Requested-payment-status-payload.interface';
import { RequestedPaymentStatusResponse } from '../interfaces/RequestedPaymentStatus/Requested-payment-status-response.interface';
import { redirectToErrorPage } from '../utils/redirectToErrorPage';

const REQUESTED_PAYMENT_STATUS = async (
  paymentId: RequestedPaymentStatusPayload,
): Promise<RequestedPaymentStatusResponse> => {
  try {
    const getRequestedPaymentStatusResponse = await axiosClient.post(
      API_ENDPOINTS_CONSTANTS.REQUESTED_PAYMENT_STATUS,
      paymentId,
    );
    return getRequestedPaymentStatusResponse.data as RequestedPaymentStatusResponse;
  } catch (error) {
    redirectToErrorPage();
  }
  return [] as RequestedPaymentStatusResponse;
};

const RequestPaymentStatusService = {
  REQUESTED_PAYMENT_STATUS,
};

export default RequestPaymentStatusService;
