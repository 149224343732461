import { ERROR } from '../constants/errorConstants';
import { API_ENDPOINTS_CONSTANTS } from '../constants/apiConstants';
import axiosClient from '../factories/axios.client.utils';
import { ISetPaymentRequestWithTinkPayload } from '../interfaces/SetUpPaymentRequestWithTink/Set-payment-request-with-tink-Payload.interface';
import { ISetPaymentRequestWithTinkResponse } from '../interfaces/SetUpPaymentRequestWithTink/Set-payment-request-with-tink-Response.interface';
import { redirectToErrorPage } from '../utils/redirectToErrorPage';

const SET_PAYMENT_REQUEST_WITH_TINK = async (
  userData: ISetPaymentRequestWithTinkPayload,
): Promise<ISetPaymentRequestWithTinkResponse> => {
  try {
    const PaymentResponsefromTink = await axiosClient.post(
      API_ENDPOINTS_CONSTANTS.SET_UP_PAYMENT_REQUEST_WITH_TINK,
      JSON.stringify(userData),
    );
    return PaymentResponsefromTink.data as ISetPaymentRequestWithTinkResponse;
  } catch (error) {
    const { response } = error;
    const { code } = response.data
    if (code === ERROR.EXCEED_DAILY_LIMIT_CODE) {
      return { code } as ISetPaymentRequestWithTinkResponse
    }
    else redirectToErrorPage()
  }
  return [] as ISetPaymentRequestWithTinkResponse;
};

/* 
@method: Abusive reference checker 
@param: Reference [ string ]
@response: {valid: TRUE/FALSE [string]} i.e. if response is true Ref is not Abusive 
*/
const ABUSIVE_REFERENCE_CHECKER = async (
  reference: string,
): Promise<{valid:boolean}> => {
  try {
    const Response = await axiosClient.post(
      API_ENDPOINTS_CONSTANTS.ABUSIVE_REFERENCE_CHECKER,
      JSON.stringify(reference),
    );
    return Response.data.valid;
  } catch (error) {
     redirectToErrorPage()
};
}


const SetPaymentWithTinkService = {
  SET_PAYMENT_REQUEST_WITH_TINK,
  ABUSIVE_REFERENCE_CHECKER
};

export default SetPaymentWithTinkService;