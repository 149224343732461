import { useCallback, useMemo, useState } from 'react';

import { ISetPaymentRequestWithTinkPayload } from '../../interfaces/SetUpPaymentRequestWithTink/Set-payment-request-with-tink-Payload.interface';
import { ISetPaymentRequestWithTinkResponse } from '../../interfaces/SetUpPaymentRequestWithTink/Set-payment-request-with-tink-Response.interface';
import { ISetPaymentRequestWithTinkContextData } from '../../interfaces/SetUpPaymentRequestWithTink/Set-payment-request-with-tink.context.interface';
import SetPaymentWithTinkService from '../../services/setPaymentRequest.service';

const useSetPaymentRequestTinkContextValue =
  (): ISetPaymentRequestWithTinkContextData => {
    const [setupPaymentResponse, setPaymentResponseTink] =
      useState<ISetPaymentRequestWithTinkResponse>();
      const [loadingSetupPaymentRequest, setLoading] = useState(false);

    const SetPayemnetWithTink = useCallback(
      async (userData: ISetPaymentRequestWithTinkPayload) => {
        try {
        setLoading(true);
          const {tinkRedirectUrl,code} =
            await SetPaymentWithTinkService.SET_PAYMENT_REQUEST_WITH_TINK(
              userData,
            );
        setPaymentResponseTink({tinkRedirectUrl,code});
        } catch (error) {
        setLoading(false);
        console.log(`Error ${error}`);
        }
      },
      [setPaymentResponseTink],
    );
    return useMemo(
      () => ({
        setupPaymentResponse,
        setPaymentResponseTink,
        SetPayemnetWithTink,
        loadingSetupPaymentRequest,
        setLoading
      }),
      [setupPaymentResponse, setPaymentResponseTink, SetPayemnetWithTink, setLoading, loadingSetupPaymentRequest],
    );
  };

export default useSetPaymentRequestTinkContextValue;
