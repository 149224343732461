import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { TextComponent, Subheading } from './SummaryPage.styled';
import { FIELD_NAMES, STATUS } from '../../constants/pageConstants';
import { usePaymentStatusContext } from '../../hooks/requestedPaymentStatus/request-payment-status-context-hooks';
import paymentStatusIdentifier from '../../utils/payments.util';

function PaymentStatusPrompt(): ReactElement {
  const {
    PaymentStatusResponse: { paymentStatus },
  } = usePaymentStatusContext();
  const [statusDescription, setStatusDescription] = useState<string>('');

  const renderSubHeading = useCallback(async () => {
    const status = paymentStatusIdentifier(paymentStatus);
    let description: string = '';
    switch (status) {
      case STATUS.Referred:
        description = `${FIELD_NAMES.referred}`;
        setStatusDescription(description);
        break;
      case STATUS.Failed:
        description = FIELD_NAMES.declined;
        setStatusDescription(description);
        break;
      default:
        break;
    }
  }, [setStatusDescription, paymentStatus]);

  useEffect(() => {
    renderSubHeading();
  }, [renderSubHeading]);

  return (
    <Subheading>
      <TextComponent size="s2" data-testid="prompt">
        {statusDescription}
      </TextComponent>
    </Subheading>
  );
}

export default PaymentStatusPrompt;
