import { API_ENDPOINTS_CONSTANTS } from '../constants/apiConstants';
import axiosClient from '../factories/axios.client.utils';
import { IPaymentRequestorDetailsPayload } from '../interfaces/PaymentRequestorDetails/Payment-requestor-detail-payload.interface';
import { IPaymentRequestorDetailResponse } from '../interfaces/PaymentRequestorDetails/Payment-requestor-detail-response.interface';
import { redirectToErrorPage } from '../utils/redirectToErrorPage';

const PAYMENT_REQUESTOR_DETAILS = async (
  abbreviation: IPaymentRequestorDetailsPayload,
): Promise<IPaymentRequestorDetailResponse> => {
  try {
    const getPaymentResponse = await axiosClient.post(
      API_ENDPOINTS_CONSTANTS.PAYMENT_REQUESTOR_DETAILS,
      JSON.stringify(abbreviation),
    );
    return getPaymentResponse.data as IPaymentRequestorDetailResponse;
  } catch (error) {
    redirectToErrorPage();
  }
  return [] as IPaymentRequestorDetailResponse;
};

const PaymentRequestorService = {
  PAYMENT_REQUESTOR_DETAILS,
};

export default PaymentRequestorService;
